<script setup lang="ts">
import PrimaryBtn from "@/components/buttons/PrimaryBtn.vue";
import Hero from '~/components/landing/Hero.vue';
import ContactForm from '../components/contact/contactForm.vue'
import Button from 'primevue/button';
import ChartIcon from '@/assets/icons/ChartIcon.vue';
import LightningIcon from '@/assets/icons/LightningIcon.vue'
import TaskDoneIcon from '@/assets/icons/TaskDoneIcon.vue'

//META
definePageMeta({
    layout: 'landing',
    title: 'Rejoignez-nous',
    description: 'Rejoignez-nous pour commencer à gagner de l\'argent avec votre propriété',
    keywords: 'inscription, propriétaire, hôtel, auberge, appartement, gagner de l\'argent, location, tourisme, voyage, rdc, congo, kinshasa, goma, lubumbashi, matadi, kisangani, kananga, mbuji-mayi, bukavu, tshikapa, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit, mbuji-mayi, kalemie, kolwezi, likasi, bandundu, kikwit',
})

//DATA
const productPic = ref('')
const section1Visible = ref(false)
const section2Visible = ref(false)
const section3Visible = ref(false)

const section1Ref = ref<HTMLElement | null>(null)
const section2Ref = ref<HTMLElement | null>(null)
const section3Ref = ref<HTMLElement | null>(null)

//HOOKS
const supabase = useSupabaseClient();




//METHODS
const loadPics = async () => {
    productPic.value = await supabase.storage.from('Pics').getPublicUrl('Ndaku_product_pic.png').data.publicUrl
}

//LIFE CYCLE HOOKS
onBeforeMount(async () => {
    await loadPics()
})

onMounted(() => {
    const observerOptions = { threshold: 0.45 }

    const createObserver = (ref: Ref<HTMLElement | null>, visibleRef: Ref<boolean>) => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                visibleRef.value = true
                observer.unobserve(entry.target)
            }
        }, observerOptions)

        if (ref.value) observer.observe(ref.value)
    }

    createObserver(section1Ref, section1Visible)
    createObserver(section2Ref, section2Visible)
    createObserver(section3Ref, section3Visible)
})
</script>


<template>
    <Hero />

    <section ref="section1Ref"
        class="snap-start px-4 py-8 flex flex-col justify-center items-center gap-6 md:px-6 lg:min-h-[69em] lg:p-12 lg:py-12 text-center">
        <h1 class="font-bold text-3xl text-[#2C3E50] mb-3">
            Découvrez les bénéfices incomparables de Ndaku pour votre établissement
        </h1>

        <p class="text-sm text-center text-gray-500 max-w-[45em]">
            Nous comprenons les défis uniques que rencontrent les auberges et petits hôtels. C’est pourquoi nous avons
            créé une plateforme innovante conçue pour transformer la gestion de votre établissement
        </p>
        <div class="lg:grid-rows-[2fr_1fr] lg:grid flex flex-col h-full border-[1px] rounded-xl shadow-xl p-8 gap-8 lg:max-w-[80em]"
            :class="{ 'animate-turn-in-y': section1Visible }">

            <div class=" lg:grid lg:grid-cols-3 lg:grid-rows-1 h-full rounded-xl flex flex-col gap-16">
                <h6 class="text-lg font-semibold text-[#2C3E50]]">
                    Ce que Ndaku vous rapporte
                </h6>
                <!-- Save time -->
                <div class="flex flex-row gap-4 justify-start md:justify-center rounded-md ">
                    <TaskDoneIcon class="fill-[rgb(122,47,72)] text-[#2C3E50] text-4xl" />
                    <div class="text-left md:text-center flex flex-col gap-2">
                        <h1 class="text-2xl font-bold mb-3 shiny-text">
                            Gagnez du Temps Précieux
                        </h1>
                        <span class="text-gray-500 text-left">
                            Automatisez les tâches clés et libérez-vous du temps pour offrir une expérience
                            mémorable à vos hôtes.
                        </span>
                    </div>
                </div>

                <!-- Simplify operations -->
                <div class="flex flex-row gap-4  justify-start md:justify-center rounded-md">
                    <LightningIcon class=" text-[#2C3E50] text-4xl" />
                    <div class="text-left md:text-center flex flex-col gap-2">
                        <h1 class="text-2xl font-bold mb-3 shiny-text">
                            Simplifiez Votre Gestion
                        </h1>
                        <span class="text-gray-500 ">
                            Suivez et organisez vos réservations en quelques clics.
                        </span>
                    </div>
                </div>


                <!-- Boost revenue -->
                <div class="flex flex-row gap-4  justify-start md:justify-center rounded-md ">
                    <ChartIcon class="fill-[#2C3E50] text-[#2C3E50] text-4xl" />
                    <div class="text-left md:text-center flex flex-col gap-2">
                        <h1 class="text-2xl font-bold mb-3 shiny-text">
                            Boostez Vos Revenus
                        </h1>
                        <span class="text-gray-500 ">
                            Transformez votre établissement et maximisez vos profits avec la plateforme révolutionnaire
                            de Ndaku.
                        </span>
                    </div>
                </div>
            </div>

            <div class="rounded-lg gap-4 p-4 bg-gray-100 text-left">
                <h6 class="text-xl font-semibold mb-2">
                    Découvrez tous les avantages de Ndaku
                </h6>
                <div class="flex flex-col justify-between items-start gap-4 md:items-center md:flex-row ">
                    <span class="md:w-3/4 text-left leading-6">
                        Passez à la gestion optimisée: Ndaku offre de nombreux avantages pour les propriétaires
                        d'auberges et de petits hôtels. Découvrez nos
                        <span class=" text-[#2C3E50] font-semibold">tarifs et offres</span>
                        et voyez ce que cela peut faire pour votre établissement.
                    </span>
                    <nuxt-link to="/pricing">
                        <Button text raised class="p-2 bg-gray-200 rounded-lg sm:py-4">
                            Tarifs & Avantages
                        </Button>
                    </nuxt-link>
                </div>
            </div>
        </div>
    </section>



    <section ref="section2Ref" class="snap-start flex flex-col  lg:text-left px-4 py-8 min-h-[50em] ">
        <div class=" p-4 my-8 h-full  lg:text-left  flex flex-col gap-10 rounded-xl lg:!grid lg:grid-cols-2 lg:py-24 lg:px-20 lg:min-w-[70em]"
            :class="{ 'animate-slide-in': section2Visible }">

            <div class="min-h-[30em] shadow-xl lg:min-h-[50em] rounded-lg" :style="{
                backgroundImage: `url(${productPic})`,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
            }">
            </div>

            <div class="flex flex-col  gap-6 justify-center items-center lg:p-14 lg:justify-start">
                <h4 class="shiny-text font-medium text-center lg:text-left">
                    Simplifiez votre gestion et Maximisez votre potentiel
                </h4>
                <p class="text-gray-600">
                    Avec Ndaku vous avez une gestion efficace et simplifiée de votre entreprise hôtelière. Notre
                    plateforme intuitive vous permet de gérer facilement vos réservations, vos clients, votre
                    comptabilité et vos chambres, le tout en un seul endroit.
                </p>

                <div class=" flex flex-col gap-6">
                    <div
                        class="flex flex-row !flex-nowrap p-2 gap-4 items-center  bg-[#B39C9E] rounded-lg dark:bg-blue-400/10">
                        <TaskDoneIcon class=" text-[#2C3E50] text-4xl" />
                        <span class="!flex-1">
                            Vos données précieuses en un clin d'œil
                        </span>
                    </div>

                    <div
                        class="flex flex-row !flex-nowrap gap-4 p-2 items-center  bg-[#B39C9E] rounded-lg dark:bg-blue-400/10">
                        <TaskDoneIcon class=" text-[#2C3E50] text-4xl " />
                        <span class="!flex-1">
                            Performance visualisée avec des graphiques clairs et détaillés
                        </span>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section ref="section3Ref"
        class=" snap-start px-4 py-8 min-h-[90em] md:px-6 lg:px-8 lg:py-40 lg:min-h-[69em] flex flex-col items-center justify-center">
        <h1 class="font-bold text-2xl mb-3">
            Des <span class="text-[#2C3E50]">questions ?</span> Parlons-en
        </h1>

        <p class="text-sm text-center text-gray-500 max-w-[45em]">
            Notre engagement est de fournir un service exceptionnel. <br> Nous comprenons que chaque
            détail compte et nous sommes là pour vous aider
        </p>

        <ContactForm :class="{ 'animate-slide-in-x': section3Visible }" />
    </section>
</template>

<style scoped>
@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-in-x {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scale-in {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes turn-in-y {
    0% {
        transform:
            perspective(1200px) translateX(0px) translateY(0px) scale(0.95) rotate(0deg) rotateX(10deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px)
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes shine {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

.animate-slide-in {
    animation: slide-in 2s ease-out forwards;
}

.animate-fade-in {
    animation: fade-in 2s ease-out forwards;
}

.animate-scale-in {
    animation: scale-in 2s ease-out forwards;
}

.animate-turn-in-y {
    animation: turn-in-y 1s ease-in forwards;
}

.animate-slide-in-x {
    animation: slide-in-x 0.75s ease-in forwards;
}

.shiny-text {
    position: relative;
    display: inline-block;
    color: inherit;
}

.shiny-text::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    transform: skewX(-30deg);
    animation: shine 2s infinite;
}
</style>