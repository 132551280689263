<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linejoin="round" d="M11 14H6L9.5 2H16l-3 8h5l-8 12z"></path>
    </svg>
</template>

<script lang="ts">
export default {
    name: 'LightningIcon'
}
</script>