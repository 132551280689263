<script setup lang="ts">
import { ref, onMounted } from 'vue';
import PrimaryBtn from "../buttons/PrimaryBtn.vue";
import Button from 'primevue/button';

// DATA
const headerRef = ref(null);

// METHODS


// LIFE CYCLE HOOKS
onMounted(async () => {

    // Apply fade-in animation class
    if (headerRef.value) {
        headerRef.value.classList.add('fade-in');
    }
});
</script>

<template>
    <header ref="headerRef" class="py-6 h-[50em] lg:h-[58em] flex justify-center items-center">
        <section class="md:my-8 md:mx-12">
            <div ref="animatable" class="flex flex-col gap-8 sm:gap-16 px-4 sm:px-12 h-full lg:w-[80em]">
                <h1 class="text-4xl md:text-6xl text-center tracking-wider">
                    Transformez chaque séjour en un souvenir inoubliable
                </h1>
                <h6 class="text-gray-600 text-sm text-center md:text-left md:text-base">
                    Le système de gestion pour boutique hôtels qui fait la différence.
                </h6>

                <div class="flex flex-col sm:flex-row justify-center items-center gap-10">
                    <nuxt-link to="/joinus">
                        <PrimaryBtn class="px-2 py-2 sm:py-4">
                            Découvrez une démo personnalisée
                        </PrimaryBtn>
                    </nuxt-link>
                    <nuxt-link to='/contact'>
                        <Button link severity="contrast"
                            class="px-2 py-2 bg-white  shadow-md text-[#2C3E50] rounded-lg sm:py-4">
                            Discutons de vos besoin
                        </Button>
                    </nuxt-link>
                </div>
            </div>
        </section>
    </header>
</template>

<style>
.fade-in {
    animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        scale: 0.95;
    }

    to {
        opacity: 1;
        scale: 1
    }
}
</style>
